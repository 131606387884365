import styled from "styled-components";
import { Color } from "../../../styles";

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  background-color: #333;

  > img {
    display: block;
    max-width: 240px;
    width: auto;
    margin: 0 auto 48px auto;
    padding-top: 48px;
  }
`;

export const Input = styled.input`
  display: block;
  width: auto;
  max-width: 240px;
  height: 48px;
  box-sizing: border-box;
  padding: 6px 12px;
  margin: 48px auto 24px auto;

  background: none;
  border-radius: 4px;
  border: 1px solid ${Color.ORANGE_PRIMARY};

  color: rgba(255, 255, 255, 0.9);
`;

export const TryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  max-width: 240px;
  margin: 24px auto;

  border-radius: 4px;
  background-color: ${Color.GREEN_PRIMARY};

  color: rgba(255, 255, 255, 0.9);
  border-radius: 24px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  line-height: 140%;

  :hover {
    cursor: pointer;
  }
`;

export const Coordinates = styled.div`
  font-family: "Courier New", Courier, monospace;

  text-align: center;

  > a {
    display: block;
    padding-top: 24px;
    color: ${Color.ORANGE_PRIMARY};
  }
`;
