import React from "react";

import Tillebille from "../components/tillebille/Tillebille/Tillebille";
import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";

export default () => (
  <div>
    <CustomHelmet
      title="Platoon DJs - Tillebille"
      description=""
      keywords=""
      canonical=""
    />
    <Tillebille />
  </div>
);
