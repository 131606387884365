import React from "react";

import * as Styles from "./Tillebille.styles";

import closedImage from "../../../assets/images/tillebille/closed.jpg";
import openImage from "../../../assets/images/tillebille/open.png";
import { ApiService } from "../../../services/ApiService";

const defaultState = {
  open: false,
  password: "",
};

const Tillebille = () => {
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [data, setData] = React.useState(null);

  const fetchData = () => {
    ApiService.get("/tillebille/")
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {});
  };
  const tryPassword = () => {
    const correctPassword = data.password;
    if (!correctPassword) {
      return;
    }
    if (password === correctPassword.toUpperCase()) {
      setOpen(true);
    } else {
      alert("FEL LÖSENORD");
      setPassword("");
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (!data) {
    return <Styles.Container>Laddar...</Styles.Container>;
  }
  return (
    <Styles.Container>
      {open ? (
        <>
          <img src={openImage} />
          <Styles.Coordinates>
            {data.latitude}, {data.longitude}
            <br />
            <a
              target="_blank"
              href={`http://www.google.com/maps/place/${data.latitude},${data.longitude}`}
            >
              Öppna i Maps
            </a>
          </Styles.Coordinates>
        </>
      ) : (
        <>
          <img src={closedImage} />
          <Styles.Input
            placeholder="Lösenord"
            type="text"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value.toUpperCase());
            }}
          />
          <Styles.TryButton onClick={tryPassword}>
            Pröva lösenord
          </Styles.TryButton>
        </>
      )}
    </Styles.Container>
  );
};

export default Tillebille;
